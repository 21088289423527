import { FC, FunctionComponent, SVGProps } from 'react';
import { DARK_GREY } from '../../../theme/colors';
import { Box, makeStyles, SvgIcon, Typography } from '../../../utils/material';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    lineHeight: '1.5',
    letterSpacing: '0.15px',
  },
  titleContainer: {
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: '26px',
    width: '26px',
  },
  iconContainer: {
    height: '40px',
    minWidth: '40px',
    marginRight: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    backgroundColor: 'rgba(15, 110, 255, 0.16)',
  },
  description: {
    marginTop: '16px',
    fontSize: '0.75rem;',
    lineHeight: 1.43,
    letterSpacing: 0.25,
    color: DARK_GREY,
  },
}));

interface Props {
  title: string;
  description: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

const StepTitle: FC<Props> = ({ title, description, icon }) => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.titleContainer}>
        <Box className={classes.iconContainer}>
          <SvgIcon viewBox="0 0 26 26" className={classes.icon} component={icon} color="primary" />
        </Box>
        <Box>
          <Typography className={classes.title}>{title}</Typography>
        </Box>
      </Box>
      <Box className={classes.description}>
        <Typography>{description}</Typography>
      </Box>
    </div>
  );
};

export default StepTitle;
