import { ChangeEvent, FC, useState } from 'react';
import { Enum } from '../../../../domain/enum';
import { Permission, Permissions, PermissionSubcategory } from '../../../../domain/permission';
import { BLUE_5 } from '../../../../theme/colors';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ExpandMoreIcon,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '../../../../utils/material';

interface Props {
  open: boolean;
  onSave: (values: Permissions[]) => void;
  onCancel: () => void;
  permissionCategory?: Enum[];
  permissionSubcategory?: PermissionSubcategory[];
  permissionList?: Permission[];
}

const useStyles = makeStyles((theme) => ({
  heading: {
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 'bold',
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  tab: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.52rem !important',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem !important',
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 40px 16px !important',
  },
  chip: {
    backgroundColor: BLUE_5,
    color: 'white',
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.typography.pxToRem(8),
      marginLeft: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '15px',
      fontSize: theme.typography.pxToRem(10),
    },
  },
}));

const AddPermissionsModal: FC<Props> = ({
  open,
  onSave,
  onCancel,
  permissionCategory,
  permissionList,
  permissionSubcategory,
}) => {
  const classes = useStyles();
  const [categoryTab, setCategoryTab] = useState('ADMIN');
  const [permissionChecked, setPermissionChecked] = useState<Permissions[]>([]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_event: ChangeEvent<{}>, newValue: Permissions) => {
    setCategoryTab(newValue);
  };

  const handleChecked = (value: Permissions) => {
    const isPresent = permissionChecked.includes(value);
    let addReadCheck: Permissions | undefined;

    if (value.includes('CREATE') || value.includes('UPDATE') || value.includes('INVITE')) {
      const aux = value.split('_');
      aux[0] = 'READ';
      const readValue = aux.join('_');
      addReadCheck = permissionList?.find((permission) => permission.value === readValue)?.value;
    }

    setPermissionChecked((prev) =>
      isPresent
        ? prev.filter((x) => x !== value)
        : addReadCheck
        ? [...prev, value, addReadCheck]
        : [...prev, value],
    );
  };

  const getPermissionListByCategory = () => {
    return permissionSubcategory
      ?.filter(
        (p) =>
          p.permissionCategory === categoryTab &&
          permissionList &&
          permissionList.filter((pl) => pl.permissionSubcategory === p.value).length > 0,
      )
      .map((subCat) => {
        const permissionsBySubCategory = permissionList?.filter(
          (p) => p.permissionSubcategory === subCat.value,
        );
        return (
          <Accordion key={subCat.value}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>
                {subCat.displayName}{' '}
                <Chip
                  className={classes.chip}
                  size="small"
                  label={`${
                    permissionsBySubCategory?.filter((p) => permissionChecked.includes(p.value))
                      .length
                  } / ${permissionsBySubCategory?.length}`}
                />
              </Typography>
              <Typography className={classes.secondaryHeading}>{subCat.description}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {permissionList
                ?.filter((p) => p.permissionSubcategory === subCat.value)
                .map((p) => (
                  <Box key={p.displayName} m={1}>
                    <Grid container style={{ alignItems: 'baseline' }}>
                      <Grid item xs={3}>
                        {p.displayName}
                      </Grid>
                      <Grid item xs={8}>
                        {p.description}
                      </Grid>
                      <Grid item xs={1}>
                        <Checkbox
                          size="small"
                          checked={permissionChecked.includes(p.value)}
                          color="primary"
                          onClick={() => handleChecked(p.value)}
                        />
                      </Grid>
                    </Grid>
                    <Divider style={{ marginBottom: '5px' }} />
                  </Box>
                ))}
            </AccordionDetails>
          </Accordion>
        );
      });
  };

  return (
    <Dialog maxWidth="md" open={open}>
      <DialogTitle>
        <Grid container>
          <Grid item xs={12} className={classes.heading}>
            Role Permissions
          </Grid>
          <Grid item xs={12} className={classes.secondaryHeading}>
            Please select at least one permission for this role.
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ height: '400px', overflowY: 'auto' }}>
        <Tabs
          value={categoryTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          variant="fullWidth"
        >
          {permissionCategory?.map((c) => (
            <Tab key={c.value} className={classes.tab} label={c.displayName} value={c.value} />
          ))}
        </Tabs>
        <Box m={1}>{getPermissionListByCategory()}</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="default">
          Cancel
        </Button>
        <Button
          onClick={() => {
            setPermissionChecked([]);
            onSave(permissionChecked);
          }}
          color="primary"
          disabled={permissionChecked.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPermissionsModal;
