import { Lookup } from '../domain/base';
import { EntityType } from '../domain/entity-type';
import { NoteContext } from '../domain/note';
import { PartyLookupParams, PartyLookupResult } from '../domain/person';
import { UserLookup } from '../domain/user';
import { api } from '../utils/configuration';
import { getAxiosFetcher } from './apiClient';

export interface LookupParams {
  q?: string;
  id?: number | number[];
}

export interface NoteLookupParams {
  entityId: number;
  entityType: EntityType;
  context: NoteContext;
}

const LookupService = {
  notes: getAxiosFetcher<Lookup[], [NoteLookupParams]>((params) => [
    `${api.NOTE_URL}/entities}`,
    { params },
  ]),

  user: getAxiosFetcher<UserLookup[], [LookupParams?]>((params = {}) => [
    `${api.USER_URL}/lookup`,
    { params },
  ]),

  party: getAxiosFetcher<PartyLookupResult[], [PartyLookupParams?]>((params) => [
    `${api.PARTIES_URL}/lookup`,
    { params },
  ]),
};

export type LookupType = keyof typeof LookupService;

export default LookupService;
