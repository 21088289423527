import { LookupParams } from '../services/lookup';
import { PropifyEntity } from './base';
import { EmailAddress } from './emails';
import { LookupResult } from './lookup';
import { Phone } from './phone';
import { Resident } from './resident';

export enum PartyType {
  PERSON = 'PERSON',
  ORGANIZATION = 'ORGANIZATION',
}

export enum PartyRoleType {
  PROSPECT = 'PROSPECT',
  RESIDENT = 'RESIDENT',
  USER = 'USER',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
  VENDOR = 'VENDOR',
  COLLECTIONS_AGENCY = 'COLLECTIONS_AGENCY',
  GOVERNMENT = 'GOVERNMENT',
  HOUSING_AUTHORITY = 'HOUSING_AUTHORITY',
  HOA = 'HOA',
  LAW_OFFICE = 'LAW_OFFICE',
  REAL_ESTATE_OFFICE = 'REAL_ESTATE_OFFICE',
  UTILITY_PROVIDER = 'UTILITY_PROVIDER',
}

export interface PartyRole extends PropifyEntity {
  partyId: number;
  partyRoleId: number;
}

export interface Party extends PropifyEntity {
  emailAddresses: EmailAddress[];
  phones: Phone[];
  partyType: PartyType;
}

export interface Person extends Party {
  firstName: string;
  lastName: string;
  email: string;
  qualifiedLead: boolean;
  residents: Resident[];
  yardiProspectCode?: string;
  yardiProspectHmy?: number;
}

export interface PartyLookupParams extends LookupParams {
  partyRoleId?: number | number[];
}

export interface PartyLookupResult extends LookupResult {
  emailAddresses: EmailAddress[];
  phones: Phone[];
}
