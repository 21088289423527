import { Formik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
} from '../../../../utils/material';

interface Props {
  roleName: string;
  open: boolean;
  onSave: (values: AddRoleValue) => void;
  onCancel: () => void;
}

export interface AddRoleValue {
  name: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
});

const useStyles = makeStyles((theme) => ({
  heading: {
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 'bold',
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
}));

const AddRoleNameModal: FC<Props> = ({ open, onSave, onCancel, roleName }) => {
  const classes = useStyles();
  return (
    <>
      <Formik<AddRoleValue>
        enableReinitialize
        initialValues={{ name: roleName }}
        validationSchema={validationSchema}
        onSubmit={onSave}
      >
        {({ handleSubmit, values, handleChange }) => (
          <Dialog open={open}>
            <DialogTitle>
              <Grid container>
                <Grid item xs={12} className={classes.heading}>
                  Role Name
                </Grid>
                <Grid item xs={12} className={classes.secondaryHeading}>
                  Please provide a name for this role.
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <TextField
                name="name"
                id="name"
                label="Name"
                multiline
                value={values.name}
                fullWidth
                onChange={handleChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} color="default">
                Cancel
              </Button>
              <Button onClick={() => handleSubmit()} color="primary">
                Next
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default AddRoleNameModal;
