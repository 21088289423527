import type { Enum } from '../domain/enum';
import type { EnumType } from '../utils/configuration';
import { api } from '../utils/configuration';
import { getAxiosFetcher, getRawAxiosFetcher } from './apiClient';

export const EnumerationService = {
  getEnums: getRawAxiosFetcher<Enum[], [enumType: EnumType]>(() =>
    getAxiosFetcher((enumType) => [`${api.ENUMERATION_URL}/${enumType}`]),
  ),
};
