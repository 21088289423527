import { getHistory } from '@/history';
import { EnvironmentService } from '@/services/environment';
import type { BrowserOptions } from '@sentry/react';
import {
  BrowserTracing,
  captureException,
  init,
  reactRouterV5Instrumentation,
  withSentryRouting,
} from '@sentry/react';
import { Route } from 'react-router-dom';

const options: BrowserOptions = {
  dsn: 'https://047d00f9653e4028ad75e591b7f9bdeb@o372675.ingest.sentry.io/5896639',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV5Instrumentation(getHistory()),
    }),
  ],
  tracesSampleRate: 1.0,
};

export const SentryService = {
  init: (config: BrowserOptions = {}) => {
    const isProd = process.env.NODE_ENV === 'production';
    const release = process.env.RELEASE_NAME;

    if (isProd && release) {
      EnvironmentService.getEnvironment().then((environment) => {
        if (environment === 'stage' || environment === 'production') {
          init({
            ...options,
            ...config,
            environment,
            release,
          });
        }
      });
    }
  },
  trackError: captureException,
};

export const SentryRoute = withSentryRouting(Route);
