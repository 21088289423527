import type { Enum } from '@/domain/enum';
import type { Permission, Permissions, PermissionSubcategory } from '@/domain/permission';
import type { UserRoleType } from '@/domain/user';
import { EnumerationService } from '@/services/enumeration';
import { PermissionsService } from '@/services/permissions';
import { UserRoleTypesService } from '@/services/user-role-types';
import { BLUE_5, CARD_GREY, DARK_GREY_4 } from '@/theme/colors';
import { EnumType } from '@/utils/configuration';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AddIcon,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  EditIcon,
  ExpandMoreIcon,
  Grid,
  LockIcon,
  makeStyles,
  SaveIcon,
  Tab,
  Tabs,
  Typography,
} from '@/utils/material';
import { handleError } from '@/utils/utils';
import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import AddPermissionsModal from './components/AddPermissionsModal';
import type { AddRoleValue } from './components/AddRoleNameModal';
import AddRoleNameModal from './components/AddRoleNameModal';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      height: 'auto%',
    },
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  leftCard: {
    overflowY: 'auto',
    height: '100%',
  },
  rightCard: {
    overflowY: 'auto',
    height: '100%',
  },
  card: {
    boxShadow: 'none',
    height: '100%',
  },
  title: {
    color: BLUE_5,
    fontWeight: 'bold',
    lineHeight: 1.43,
    letterSpacing: 0.25,
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.8rem;',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem;',
    },
  },
  cardBody: {
    marginTop: '45%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    backgroundColor: BLUE_5,
    color: 'white',
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.52rem !important',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem !important',
    },
  },
  active: {
    backgroundColor: BLUE_5,
    color: 'white',
    fontSize: '1rem;',
    fontWeight: 'bold',
  },
  noActive: {
    backgroundColor: CARD_GREY,
    color: 'white',
    fontSize: '1rem;',
    fontWeight: 'bold',
  },
  cardContent: {
    height: '93%',
    overflowY: 'auto',
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.7rem;',
      padding: '10px !important',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem;',
      padding: '15px !important',
    },
  },
  tab: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.52rem !important',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem !important',
    },
  },
  heading: {
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 'bold',
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 40px 16px !important',
  },
  chip: {
    backgroundColor: BLUE_5,
    color: 'white',
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.typography.pxToRem(8),
      marginLeft: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '15px',
      fontSize: theme.typography.pxToRem(10),
    },
  },
  globalRole: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
}));

export const RolesAndPermissions: FC = () => {
  const classes = useStyles();
  const [categoryTab, setCategoryTab] = useState('ADMIN');
  const [permissionList, setPermissionList] = useState<Permission[]>();
  const [roleList, setRoleList] = useState<UserRoleType[]>();
  const [roleIdActive, setRoleIdActive] = useState<number>();
  const [permissionCategory, setPermissionCategory] = useState<Enum[]>();
  const [permissionSubcategory, setPermissionSubcategory] = useState<PermissionSubcategory[]>();
  const [roleName, setRoleName] = useState<string>('');
  const [showRoleNameModal, setShowRoleNameModal] = useState<boolean>(false);
  const [showPermissionsModal, setShowPermissionsModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [permissionChecked, setPermissionChecked] = useState<Permissions[]>([]);

  const load = () => {
    setLoading(true);
    Promise.all([UserRoleTypesService.search(), PermissionsService.search()])
      .then(([roleTypes, p]) => {
        if (roleTypes.length > 0) {
          setRoleIdActive(roleIdActive ?? roleTypes[0].id);
          setPermissionChecked(
            roleTypes.find((r) => r.id === roleIdActive)?.permissions ?? roleTypes[0].permissions,
          );
        }
        setRoleList(roleTypes);
        setPermissionList(p);
      })
      .catch((error) => {
        handleError(error, { toastMessage: 'There was an error while loading the data' });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    EnumerationService.getEnums(EnumType.PERMISSION_CATEGORY)
      .then((data) => setPermissionCategory(data))
      .catch((error) => {
        handleError(error, {
          toastMessage: 'There was an error while loading the permission categories.',
        });
      });
    EnumerationService.getEnums(EnumType.PERMISSION_SUBCATEGORY)
      .then((data) => setPermissionSubcategory(data as PermissionSubcategory[]))
      .catch((error) => {
        handleError(error, {
          toastMessage: 'There was an error while loading the permission subcategories.',
        });
      });
    load();
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_event: ChangeEvent<{}>, newValue: string) => {
    setCategoryTab(newValue);
  };

  const handleChecked = (value: Permissions) => {
    const isPresent = permissionChecked.includes(value);
    let addReadCheck: Permissions | undefined;

    if (value.includes('CREATE') || value.includes('UPDATE') || value.includes('INVITE')) {
      const aux = value.split('_');
      aux[0] = 'READ';
      const readValue = aux.join('_');
      addReadCheck = permissionList?.find((permission) => permission.value === readValue)?.value;
    }

    setPermissionChecked((prev) =>
      isPresent
        ? prev.filter((x) => x !== value)
        : addReadCheck
        ? [...prev, value, addReadCheck]
        : [...prev, value],
    );
  };

  const getPermissionListByCategory = () => {
    return permissionSubcategory
      ?.filter(
        (p) =>
          p.permissionCategory === categoryTab &&
          permissionList &&
          permissionList.filter((pl) => pl.permissionSubcategory === p.value).length > 0,
      )
      .map((subCat) => {
        const permissionsBySubCategory = permissionList?.filter(
          (p) => p.permissionSubcategory === subCat.value,
        );
        return (
          <Accordion key={subCat.value}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>
                {subCat.displayName}
                <Chip
                  className={classes.chip}
                  size="small"
                  label={`${
                    permissionsBySubCategory?.filter((p) => permissionChecked.includes(p.value))
                      .length
                  } / ${permissionsBySubCategory?.length}`}
                />
              </Typography>
              <Typography className={classes.secondaryHeading}>{subCat.description}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {permissionsBySubCategory?.map((p) => {
                return (
                  <Box key={p.displayName} m={1}>
                    <Grid container style={{ alignItems: 'baseline' }}>
                      <Grid item xs={3}>
                        {p.displayName}
                      </Grid>
                      <Grid item xs={8}>
                        {p.description}
                      </Grid>
                      <Grid item xs={1}>
                        <Checkbox
                          disabled={!isEdit}
                          size="small"
                          checked={permissionChecked.includes(p.value)}
                          color="primary"
                          onClick={() => handleChecked(p.value)}
                        />
                      </Grid>
                    </Grid>
                    <Divider style={{ marginBottom: '5px' }} />
                  </Box>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      });
  };

  const handleRoleActive = (id: number) => {
    setRoleIdActive(id);
    setPermissionChecked(roleList?.find((r) => r.id === id)?.permissions ?? []);
  };

  const getRoleListCard = () => {
    return roleList?.map((r) => {
      return (
        <Box m={2} key={r.id}>
          <Card
            className={r.id === roleIdActive ? classes.active : classes.noActive}
            onClick={() => handleRoleActive(r.id)}
          >
            <CardContent className={classes.cardContent}>
              {r.isGlobal ? (
                <div className={classes.globalRole}>
                  {r.name}
                  <LockIcon fontSize="small" />
                </div>
              ) : (
                <span>{r.name}</span>
              )}
            </CardContent>
          </Card>
        </Box>
      );
    });
  };

  const handleRoleName = (values: AddRoleValue) => {
    setRoleName(values.name);
  };

  useEffect(() => {
    if (roleName !== '') {
      setShowRoleNameModal(false);
      setShowPermissionsModal(true);
    }
  }, [roleName]);

  const handleCancelModals = () => {
    setRoleName('');
    setShowRoleNameModal(false);
    setShowPermissionsModal(false);
  };

  const handleEdit = () => setIsEdit(true);

  const handleCancelEdit = () => {
    setPermissionChecked(roleList?.find((r) => r.id === roleIdActive)?.permissions ?? []);
    setIsEdit(false);
  };

  const handleSave = () => {
    const role = roleList?.find((r) => r.id === roleIdActive);
    UserRoleTypesService.update({ ...role, permissions: permissionChecked } as UserRoleType)
      .then(() => {
        load();
      })
      .catch((error) => {
        handleError(error, { toastMessage: 'There was an error while updating the permissions' });
      })
      .finally(() => {
        setIsEdit(false);
      });
  };

  const saveRole = (permissions: Permissions[]) => {
    UserRoleTypesService.create({ name: roleName, permissions })
      .then(() => {
        load();
      })
      .catch((error) => {
        handleError(error, { toastMessage: 'There was an error while creating the role' });
      })
      .finally(() => {
        handleCancelModals();
      });
  };

  if (loading) {
    return (
      <Box alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} sm={4} className={classes.leftCard}>
          <Card className={classes.card} style={{ backgroundColor: DARK_GREY_4 }}>
            <CardHeader
              title={
                <>
                  <Grid container style={{ alignItems: 'baseline' }}>
                    <Grid item xs={8} sm={9} className={classes.title}>
                      Roles And Permissions
                    </Grid>
                    {!!roleIdActive && (
                      <Grid item xs={4} sm={3} className={classes.title}>
                        <Button
                          size="small"
                          variant="contained"
                          className={classes.button}
                          startIcon={<AddIcon />}
                          onClick={() => setShowRoleNameModal(true)}
                        >
                          Add Role
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Divider style={{ marginTop: '10px' }} />
                </>
              }
            />
            {!roleIdActive ? (
              <Box className={classes.cardBody}>
                <Box className={classes.title}>No Role Created</Box>
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    style={{ margin: '10px' }}
                    onClick={() => setShowRoleNameModal(true)}
                  >
                    Add Role
                  </Button>
                </Box>
                <Box>Please create your first role to assign to users.</Box>
              </Box>
            ) : (
              getRoleListCard()
            )}
          </Card>
        </Grid>
        {!!roleIdActive && (
          <Grid item xs={12} sm={8} className={classes.rightCard}>
            <Card className={classes.card}>
              <Tabs
                value={categoryTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                variant="fullWidth"
              >
                {permissionCategory?.map((c) => (
                  <Tab
                    key={c.value}
                    className={classes.tab}
                    label={c.displayName}
                    value={c.value}
                  />
                ))}
              </Tabs>
              <CardContent className={classes.cardContent}>
                <Box m={1} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  {isEdit ? (
                    <>
                      <Button
                        size="small"
                        variant="contained"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                        style={{ margin: '10px' }}
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        className={classes.button}
                        style={{ margin: '10px', backgroundColor: CARD_GREY }}
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      className={classes.button}
                      startIcon={<EditIcon />}
                      style={{ margin: '10px' }}
                      onClick={handleEdit}
                      disabled={roleList?.find((r) => r.id === roleIdActive)?.isGlobal}
                    >
                      Edit
                    </Button>
                  )}
                </Box>
                <Box m={2}>{getPermissionListByCategory()}</Box>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <AddRoleNameModal
        roleName={roleName}
        open={showRoleNameModal}
        onSave={handleRoleName}
        onCancel={() => handleCancelModals()}
      />
      <AddPermissionsModal
        open={showPermissionsModal}
        onSave={saveRole}
        onCancel={() => handleCancelModals()}
        permissionCategory={permissionCategory}
        permissionSubcategory={permissionSubcategory}
        permissionList={permissionList}
      />
    </Box>
  );
};
