import { parseJwt } from '@/services/apiClient';
import { EnvironmentService } from '@/services/environment';
import { triggerLocalStorageEvent } from './utils';

const redirectToAuthClient = () => {
  const { origin, pathname, search, hash } = window.location;

  const params = new URLSearchParams({
    // Base64-encoded to avoid conflicts with query params after redirect
    returnTo: btoa(`${pathname}${search}${hash}`),
    callbackUrl: `${origin}/auth/callback`,
  });
  const path = `/login?${params}`;

  EnvironmentService.getAuthClientUrl().then((authClientUrl) => {
    window.location.href = authClientUrl + path;
  });
};

export function logout(): void {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  redirectToAuthClient();
}

export function setAccessToken(token: string): void {
  localStorage.setItem('access_token', token);
  triggerLocalStorageEvent('access_token');
}

export function setInviteToken(token: string): void {
  localStorage.setItem('invite_token', token);
}

export function setRefreshToken(token: string): void {
  localStorage.setItem('refresh_token', token);
}

export const getAccessToken = () => localStorage.getItem('access_token');

export const getRefreshToken = () => localStorage.getItem('refresh_token');

export const getInviteToken = () => localStorage.getItem('invite_token');

export const isLoggedIn = (): boolean => {
  const token = getRefreshToken();

  return !!token;
};

export const getOrganizationId = (): number | undefined => {
  const token = getAccessToken();

  return parseJwt(token)?.orgId;
};
