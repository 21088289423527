import { useContext } from 'react';
import { ExitToAppIcon, LogoIcon, SocialEngineeringIcon } from '../assets/images/icons';
import { RegistrationContext } from '../contexts/registration';
import { AppBar, Box, makeStyles, SvgIcon, Toolbar, Typography } from '../utils/material';

const useStyles = makeStyles({
  appBar: {
    backgroundColor: 'white',
    boxShadow: '0 4px 8px 0 rgba(16, 32, 39, 0.08)',
  },
  toolbar: {
    minHeight: '56px',
  },
  icon: {
    color: '#0f6eff',
  },
  logout: {
    marginLeft: '8px',
  },
  logo: {
    height: '48px',
    width: '48px',
    marginTop: '4px',
  },
  title: {
    margin: '14px 32px 14px 12px',
    width: '100%',
  },
  saveAndExit: {
    cursor: 'pointer',
  },
});

export const Header = () => {
  const classes = useStyles();
  const { onSaveAndExit, showSaveAndExit } = useContext(RegistrationContext);

  return (
    <AppBar position="static" color="default" className={`${classes.appBar}`}>
      <Toolbar
        classes={{
          regular: classes.toolbar,
        }}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center">
            <Box>
              <SvgIcon className={classes.icon} component={SocialEngineeringIcon} />
            </Box>
            <Box className={classes.title}>
              <Typography variant="subtitle2">Propify Maintenance</Typography>
            </Box>
          </Box>
          {showSaveAndExit ? (
            <Box
              className={classes.saveAndExit}
              textAlign="center"
              display="flex"
              alignItems="center"
              onClick={onSaveAndExit}
            >
              <Typography>Save &amp; Exit</Typography>
              <SvgIcon className={`${classes.icon} ${classes.logout}`} component={ExitToAppIcon} />
            </Box>
          ) : (
            <Box textAlign="center">
              <SvgIcon viewBox="0 0 48 48" className={`${classes.logo}`} component={LogoIcon} />
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
