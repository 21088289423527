import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setRefreshToken } from '../../utils/auth';
import { Backdrop, CircularProgress } from '../../utils/material';

interface Props {
  refreshToken: string | null;
  returnTo: string | null;
}

const AuthCallbackPage: FC<Props> = ({ refreshToken, returnTo }) => {
  const history = useHistory();

  useEffect(() => {
    setRefreshToken(refreshToken || '');

    history.replace(returnTo ? atob(returnTo) : '/'); // returnTo is encoded in base64 to avoid conflicts with query params
  }, []);

  return (
    <Backdrop open>
      <CircularProgress style={{ color: '#fff' }} />
    </Backdrop>
  );
};

export default AuthCallbackPage;
