import { Service, ServiceCategory, ServiceSubcategory } from '../domain/services';
import { api } from '../utils/configuration';
import { getAxiosFetcher } from './apiClient';

export interface ServiceSearchParams {
  id?: number | number[];
}

export const ServicesService = {
  search: getAxiosFetcher<Service[], [ServiceSearchParams?]>((params) => [
    api.SERVICES_URL,
    { params },
  ]),

  getCategories: getAxiosFetcher<ServiceCategory[], []>(() => [api.SERVICES_CATEGORIES]),

  getSubcategories: getAxiosFetcher<ServiceSubcategory[], []>(() => [api.SERVICES_SUBCATEGORIES]),

  findById: getAxiosFetcher<Service, [id: number]>((id) => [`${api.SERVICES_URL}/${id}`]),
};
