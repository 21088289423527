import { VendorService, VendorServicesBulkRequest } from '../domain/services';
import { api } from '../utils/configuration';
import { crudService, getAxiosFetcher } from './apiClient';

export const VendorServicesService = {
  search: getAxiosFetcher<VendorService[], []>(() => [api.VENDOR_SERVICES]),

  bulk: (body: VendorServicesBulkRequest) =>
    crudService.post<void>(body, `${api.VENDOR_SERVICES}/bulk`),

  delete: (id: number) => crudService.delete<void>(`${api.VENDOR_SERVICES}/${id}`),
};
