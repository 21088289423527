import noop from 'lodash/noop';
import { createContext } from 'react';
import { logout } from '../utils/auth';

interface RegistrationContextType {
  onSaveAndExit: () => void;
  setOnSaveAndExit: (callback: () => void) => void;
  showSaveAndExit: boolean;
  setShowSaveAndExit: (show: boolean) => void;
}

export const RegistrationContext = createContext<RegistrationContextType>({
  onSaveAndExit: logout,
  setOnSaveAndExit: noop,
  showSaveAndExit: false,
  setShowSaveAndExit: noop,
});
