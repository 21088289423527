import type { FC } from 'react';
import { createMarkupProps, DefaultLoaderProps } from './types';

export const getIteratorValues = ({ iterations, overrideIterator }: DefaultLoaderProps) => {
  return [...new Array(iterations)].map(() => overrideIterator).join(' ');
};

export const createBreakpoints = ({
  iterateValues = '0;1;1;0;',
  begin = 4,
  utterance = 4,
  duration = 10,
  color = '#fff',
}: DefaultLoaderProps) => {
  const loaderStyles = `stop-color: ${color}; stop-opacity: 0`;
  const arrayBreaks = [...new Array(100)];
  let htmlBreakpoints = '';
  for (let vectorIndex = 0; vectorIndex < arrayBreaks.length; vectorIndex++) {
    htmlBreakpoints += `<stop offset="${vectorIndex}%" style="${loaderStyles}">
        <animate
            attributeName="stop-opacity"
            values="${iterateValues}"
            begin="${begin * 100 + vectorIndex * utterance}ms"
            dur="${duration * 100}ms"
            fill="freeze"
            repeatCount="1000"
        />
     </stop>`;
  }
  return htmlBreakpoints;
};

export const createMarkup = ({
  size = 240,
  color = '#000',
  iterations = 1,
  duration = 10,
  begin = 4,
  utterance = 4,
  overrideIterator = '0;1;1;0;',
}: createMarkupProps) => {
  const iterateValues = getIteratorValues({ iterations, overrideIterator });
  const doBreakpoints = createBreakpoints({
    iterateValues,
    begin,
    utterance,
    duration,
    color,
  });

  return {
    __html: `<svg id="propifyLoading" width="${size}" height="${size}" preserveAspectRatio="xMaxYMid meet" viewBox="0 0 444 401">
                    <defs>
                    <lineargradient id="propifyGradient" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse">
                        ${doBreakpoints}
                    </lineargradient>
                    </defs>
                    <g>
                    <clipPath id="clipPropifyLogo">
                        <path
                            style="fill: #03b1c9"
                            class="fakePropifyPath"
                            d="M222.98 325.75L443.12 297.8V174.8L335.01 87.49L222.98 195.86V325.75Z M331.09 0L111.96 57.07L0.98999 177.74L5.37 350.82L157.38 400.21V168.04L331.09 0Z"
                        />
                    </clipPath>
                    </g>
                    <path
                        clip-path="url(#clipPropifyLogo)"
                        class="fakePropifyPath"
                        fill="url(#propifyGradient)"
                        d="M222.98 325.75L443.12 297.8V174.8L335.01 87.49L222.98 195.86V325.75Z M331.09 0L111.96 57.07L0.98999 177.74L5.37 350.82L157.38 400.21V168.04L331.09 0Z"
                    />
                </svg>`,
  };
};

const Loader: FC<DefaultLoaderProps> = ({ overrideIterator, ...props }) => {
  return <div dangerouslySetInnerHTML={createMarkup({ overrideIterator, ...props })} {...props} />;
};

export default Loader;

export const PageLoading = () => (
  <Loader
    data-testid="header-logo"
    overrideIterator="1;2;1;0.9;0.4;0.9;1;2;1;"
    utterance={5}
    begin={-10}
    duration={30}
    color="#49B0E4"
    size={60}
    className="fullScreenLoader"
  />
);
