/* eslint-disable turbo/no-undeclared-env-vars */
import { handleError } from '@/utils/utils';

interface Environment {
  environment: string;
  authClientUrl: string;
}

let init: ((value: Environment | PromiseLike<Environment>) => void) | undefined;
let environmentPromise = new Promise<Environment>((resolve) => {
  init = resolve;
});

export const EnvironmentService = {
  init: () => {
    const loadEnvironment: Promise<Environment> = fetch(
      `${window.location.origin}/environment.json`,
    )
      .then((response) => response.json())
      .catch((error) => {
        handleError(error, { displayToast: false });
        return {
          environment: '',
          authClientUrl: '',
        };
      });
    environmentPromise = loadEnvironment;
    if (init) {
      init(loadEnvironment);
      init = undefined;
    }
  },

  getEnvironment: async () => {
    const fromEnv = process.env.POD_ENVIRONMENT;

    if (typeof fromEnv === 'string' && fromEnv && fromEnv !== 'undefined' && fromEnv !== 'null') {
      return fromEnv;
    }

    return (await environmentPromise).environment;
  },

  getAuthClientUrl: async () => {
    const fromEnv = process.env.AUTH_CLIENT_URL;

    if (typeof fromEnv === 'string' && fromEnv && fromEnv !== 'undefined' && fromEnv !== 'null') {
      return fromEnv;
    }

    return (await environmentPromise).authClientUrl;
  },
};
