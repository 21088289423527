import { UserRoleType } from '../domain/user';
import { api } from '../utils/configuration';
import { crudService, getAxiosFetcher } from './apiClient';

export const UserRoleTypesService = {
  search: getAxiosFetcher<UserRoleType[], []>(() => [api.USER_ROLE_TYPES_URL]),

  update: (body: UserRoleType): Promise<UserRoleType> =>
    crudService.put(body, `${api.USER_ROLE_TYPES_URL}/${body.id}`),

  create: (body: Partial<UserRoleType>): Promise<UserRoleType> =>
    crudService.post(body, api.USER_ROLE_TYPES_URL),
};
