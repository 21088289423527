import NotFoundSvg from '@/assets/404.svg?react';
import { Button, Typography } from '@/utils/material';
import { Colors } from '@propify/components';
import type { FC } from 'react';
import { useHistory } from 'react-router';
import { Box, makeStyles } from '../utils/material';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    marginBottom: '12px',
  },
  subtitle: {
    textAlign: 'center',
    color: Colors.disabled,
    marginBottom: '32px',
  },
});

const NoFoundPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleBackHome = () => history.push('/');

  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={3}>
      <NotFoundSvg />
      <Box flexDirection="column" alignItems="center" mt={3}>
        <Typography variant="h5" className={classes.title}>
          404
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          Sorry, the page you visited does not exist.
        </Typography>
        <Box textAlign="center">
          <Button color="primary" onClick={handleBackHome}>
            Back Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NoFoundPage;
