import type { User } from '@/domain/user';
import { canContinueWithoutAuth } from '@/services/apiClient';
import { UserService } from '@/services/users';
import type { FC, ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { getPermissionsFromUser, handleError, setUserPermissions } from './utils';

export interface GlobalContextProps {
  user?: User;
  setUser: (data: User) => void;
}

export const PropifyContext = createContext<GlobalContextProps>({ setUser: () => undefined });

interface Props {
  children?: ReactNode;
}

const GlobalContext: FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User>();
  const [accessTokenInStorage, setAccessTokenInStorage] = useState(
    localStorage.getItem('access_token') || '',
  );

  const fetchUser = async () => {
    try {
      const me = await UserService.me();
      setCurrentUser(me);
    } catch (error) {
      const displayToast = !canContinueWithoutAuth();
      handleError(error, { displayToast, toastMessage: 'Something went wrong fetching the user' });
    }
  };

  const setUser = useCallback((data: User) => setCurrentUser(data), [currentUser]);

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (currentUser) {
      const permissions = getPermissionsFromUser(currentUser);
      setUserPermissions(permissions);
    } else {
      setUserPermissions([]);
    }
  }, [currentUser]);

  useEffect(() => {
    const listener = () => {
      const newAccessToken = localStorage.getItem('access_token') || '';
      if (newAccessToken !== accessTokenInStorage) {
        setAccessTokenInStorage(newAccessToken);
        if (newAccessToken) {
          fetchUser();
        }
      }
    };

    document.addEventListener('storage', listener);

    return () => {
      document.removeEventListener('storage', listener);
    };
  }, [accessTokenInStorage]);

  return (
    <PropifyContext.Provider
      value={{
        user: currentUser,
        setUser,
      }}
    >
      {children}
    </PropifyContext.Provider>
  );
};

export function usePropifyContext() {
  const context = useContext(PropifyContext);
  if (context === undefined) {
    throw new Error('usePropifyContext must be used within a PropifyContext');
  }
  return context;
}

export default GlobalContext;
