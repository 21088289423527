import { useEffect } from 'react';

/**
 * NOTE: keep in mind the this event is not fired on the tab where the storage mutation was done
 */
const useStorageEvent = (
  key: string,
  callback: (ev: StorageEvent) => void,
  dependencyArray: unknown[] = [],
) => {
  useEffect(() => {
    const handler = (ev: StorageEvent) => {
      if (ev.key === key) {
        callback(ev);
      }
    };

    window.addEventListener('storage', handler);

    return () => window.removeEventListener('storage', handler);
  }, dependencyArray);
};

export default useStorageEvent;
