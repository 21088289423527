import { getHistory } from '@/history';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'react-quill/dist/quill.bubble.css';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Routes from './routes';
import theme from './theme';
import GlobalContext from './utils/globalContext';
import { ThemeProvider } from './utils/material';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const App = () => {
  return (
    <GlobalContext>
      <ThemeProvider theme={theme}>
        <Router history={getHistory()}>
          <Routes />
        </Router>
        <ToastContainer
          containerId="custom-toast-container"
          position="top-center"
          autoClose={2500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </GlobalContext>
  );
};

export default App;
