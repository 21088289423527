import type { EmailAddress } from '@/domain/emails';
import { EntityType } from '@/domain/entity-type';
import type { NoteUserMentioned } from '@/domain/note';
import type { Permissions } from '@/domain/permission';
import type { PartyRoleType } from '@/domain/person';
import type { Phone } from '@/domain/phone';
import type { User } from '@/domain/user';
import { parseJwt } from '@/services/apiClient';
import { EnvironmentService } from '@/services/environment';
import { SentryService } from '@/services/sentry';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import type { Moment } from 'moment';
import moment from 'moment';
import pLimit from 'p-limit';
import { getAccessToken } from './auth';
import { TABLE_DATE_FORMAT } from './dates';
import message from './toast';

// TODO: Implement success callback page that gets the token and store it in the localstorage
const getCallbackUrl = () => `${window.location.origin}/`;

export const buildLoginUrl = async () => {
  const href = await EnvironmentService.getAuthClientUrl();
  return `${href}/login?callbackUrl=${getCallbackUrl()}&returnTo=/`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorMessage = (error: any, defaultMessage?: string) => {
  const msg = error.displayMessage || error.message;

  if (defaultMessage) {
    return msg || defaultMessage;
  }

  return msg;
};

type HandleErrorOptions = {
  displayToast?: boolean;
  toastMessage?: string;
  toastFallbackMessage?: string;
  rethrowError?: boolean;
  sendToSentry?: boolean;
};
export const handleError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  {
    displayToast = true,
    toastMessage,
    toastFallbackMessage = 'Unexpected Error',
    rethrowError,
    sendToSentry = true,
  }: HandleErrorOptions,
) => {
  if (displayToast) {
    const errorMessage = toastMessage ?? getErrorMessage(error, toastFallbackMessage);
    message.error(errorMessage);
  }
  if (sendToSentry) {
    SentryService.trackError(error);
  }

  if (rethrowError) {
    throw error;
  }
};

export const triggerLocalStorageEvent = (key: string) => {
  const ev = new StorageEvent('storage', { key });
  window.dispatchEvent(ev);
};

export const getOrganizationRoles = () => {
  const accessToken = getAccessToken();
  if (accessToken) {
    const parsed = parseJwt(accessToken);

    return parsed?.organizationRoleTypes || [];
  }
  return [];
};

export const hasOrganizationPermissions = (organizationPermission: PartyRoleType[]) => {
  const organizationRoleTypes = getOrganizationRoles();
  return organizationPermission.some((organizationRoleType) =>
    organizationRoleTypes.includes(organizationRoleType),
  );
};

export const setUserPermissions = (permissions: Permissions[]) => {
  localStorage.setItem('permissions', JSON.stringify(permissions));
  triggerLocalStorageEvent('permissions');
};

export const getUserPermissions = memoize(
  () => {
    const value = localStorage.getItem('permissions') || '[]';
    const permissions: Permissions[] = JSON.parse(value);

    return permissions;
  },
  () => localStorage.getItem('permissions'),
);

export const getPermissionsFromUser = (user: User) => {
  const permissions = user.roles.map((role) => role.type.permissions).flat();
  return uniq(permissions);
};

export const hasPermissions = (key: Permissions) => getUserPermissions().includes(key);

export const hasOnePermissionOf = (rolePermissions: Permissions[]) => {
  return isEmpty(rolePermissions) || rolePermissions.some((p) => hasPermissions(p));
};

export const getUserOrgId = () => {
  const accessToken = getAccessToken();
  const parsed = parseJwt(accessToken);

  return parsed?.orgId ?? null;
};

export const formatPartyRole = (partyRole?: string): string => {
  switch (partyRole) {
    case 'USER':
    case 'PROPERTY_MANAGER':
      return 'Property Manager';
    case 'RESIDENT':
      return 'Resident';
    case 'VENDOR':
      return 'Vendor';
    default:
      return partyRole || '';
  }
};

export const isSameDay = (dateTime1: string, dateTime2: string): boolean => {
  return moment.utc(dateTime1).isSame(moment.utc(dateTime2), 'day');
};

export const getCardTime = (time: string | Moment) => moment(time).format('h:mm A');

export async function downloadImage(imageSrc: string, filename: string) {
  const image = await fetch(imageSrc);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement('a');
  link.href = imageURL;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return link;
}
export const capitalize = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

export const enumToLabel = (value: string) =>
  value
    .split('_')
    .map((word) => capitalize(word.toLocaleLowerCase()))
    .join(' ');

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatCurrency = (
  value?: string | number,
  options: { accountingStyle?: boolean; noEmptyDecimals?: boolean } = {
    accountingStyle: false,
    noEmptyDecimals: false,
  },
) => {
  if (value == null) {
    return '';
  }

  const numericValue = Number(value);

  if (options.accountingStyle && numericValue < 0) {
    const formatted = currencyFormatter.format(-numericValue);
    return `(${options.noEmptyDecimals ? formatted.replace(/\D00$/, '') : formatted})`;
  }

  const formatted = currencyFormatter.format(numericValue);
  return options.noEmptyDecimals ? formatted.replace(/\D00$/, '') : formatted;
};

export const replaceMentionedUserNamesByIds = (
  noteWithNames: string,
  usersMentioned: NoteUserMentioned[],
): string => {
  let noteWithIds = noteWithNames;

  if (!isEmpty(usersMentioned)) {
    usersMentioned.forEach((userMentioned) => {
      noteWithIds = noteWithIds.replaceAll(
        new RegExp(`(\\W|^)@${userMentioned.value}($|\\W)`, 'g'),
        `$1@${userMentioned.id}$2`,
      );
    });
  }
  // eslint-disable-next-line no-control-regex
  return noteWithIds.replaceAll(/[^\x00-\x7F]/g, '');
};

export const formatAge = (placedInServiceDate: string) => {
  const duration = moment.duration(moment().diff(placedInServiceDate));

  return [
    duration.years() > 0 ? `${duration.years()} y` : null,
    duration.months() > 0 ? `${duration.months()} m` : null,
    duration.days() > 0 ? `${duration.days()} d` : null,
  ]
    .filter((x) => x)
    .join(', ');
};

export const getPrimaryEmail = (emailList: EmailAddress[]): EmailAddress | undefined =>
  emailList.find((e) => e.primary) || emailList[0];

export const getPrimaryPhone = (phones: Phone[]): Phone | undefined =>
  phones.find((phone) => phone.primary) || phones[0];

/**
 * Use this function instead of 'lodash/chain'
 * @see https://skyboxcapital.atlassian.net/browse/PN-4485
 * @see https://github.com/lodash/lodash/issues/4712
 */
export const groupArrayBy = <T, KeyType = string>(
  array: T[],
  key: keyof T,
  sortFn?: (a: T, b: T) => number,
) => {
  const result = new Map<unknown, T[]>();

  array.forEach((item) => {
    const value = item[key];
    if (!result.has(value)) {
      result.set(value, [item]);
    } else {
      result.get(value)!.push(item);
    }
  });

  return [...result].map(([k, value]) => ({
    key: k as KeyType,
    value: sortFn ? value.sort(sortFn) : value,
  }));
};

export const mapDateRange = (value: [Moment | undefined, Moment | undefined]) => {
  if (Array.isArray(value)) {
    const val0 = value[0]?.format?.(TABLE_DATE_FORMAT) ?? '?';
    const val1 = value[1]?.format?.(TABLE_DATE_FORMAT) ?? '?';

    return `${val0} - ${val1}`;
  }

  return undefined;
};

export const prettierLabel = (label: string) => {
  return label
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

export const pluralize = (quantity: number, singularLabel: string, pluralLabel: string) =>
  quantity === 1 ? singularLabel : pluralLabel;

export const buildPathForEntity = (
  entityType: EntityType,
  entityId: number,
): string | undefined => {
  switch (entityType) {
    case EntityType.WORK_ORDER:
      return `/work-orders/${entityId}`;
    case EntityType.BID:
      return `/bids/${entityId}`;
    default:
      return undefined;
  }
};

export const sortArrayByDateField = <T>(array: T[], field: keyof T) =>
  // @ts-ignore
  array.slice(0).sort((i1, i2) => moment(i1[field]).valueOf() - moment(i2[field]).valueOf());

/** @see https://skyboxcapital.atlassian.net/browse/FB-266 */
export const getFileUploadLimit = (concurrentUploads = 2) => pLimit(concurrentUploads);
