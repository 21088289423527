export enum api {
  PROPERTIES_URL = '/properties',
  PROPERTY_GROUPS_URL = '/property-groups',
  REPORT_URL = '/reports',
  RESIDENT_URL = '/residents',
  RESIDENT_COLLECTIONS_URL = '/residents/collections',
  VENDOR_URL = '/vendors',
  VENDOR_SERVICES = '/vendor-services',
  LEASE_URL = '/leases',
  SCHEDULED_CHARGE_URL = '/scheduled-charges',
  NOTE_URL = '/notes',
  AUDIT_URL = '/audits',
  FILE_URL = '/files',
  UNIT_URL = '/units',
  UNIT_APPLICATION_URL = '/unit-applications',
  PHONE_URL = '/phones',
  ENUMERATION_URL = '/enumerations',
  PERSON_EVENT_URL = '/person-events',
  AUTH_URL = '/auth',
  USER_URL = '/users',
  AMENITY_URL = '/amenities',
  COMPANY_URL = '/companies',
  OPENING_HOURS_URL = '/opening-hours',
  OPENING_HOURS_OVERRIDE_URL = '/opening-hours-overrides',
  PERSON_URL = '/persons',
  PARTIES_URL = '/parties',
  ACQUISITIONS_PORTFOLIO_URL = '/acquisitions/portfolios',
  ACQUISITIONS_PROPERTY_URL = '/acquisitions/properties',
  SMART_HOME_SYSTEM_URL = '/smart-home/systems',
  SMART_HOME_CODES_URL = '/smart-home/lock-codes',
  SMART_HOME_THERMOSTAT_PROFILES_URL = '/smart-home/thermostat-profiles',
  PROSPECT_URL = '/prospects',
  LEAD_URL = '/leads',
  APPLICANT_URL = '/applicants',
  EMAIL_TEMPLATES_URL = '/email-templates',
  EMAIL_SENT_URL = '/sent-emails',
  EMAILS = '/emails',
  FORMS = '/forms',
  PET_POLICY_URL = '/pet-policies',
  NOTIFICATION_CONFIG = '/notification-config',
  SUB_SKILLS = '/subskills',
  TICKET_GROUPS_URL = '/ticket-groups',
  TICKET_GROUPS_EMAILS_URL = '/email-groups',
  TICKETS_URL = '/tickets',
  TICKET_REASONS_URL = '/ticket-reasons',
  SHOWINGS_URL = '/showings',
  UNDERWRITTING_PROFILE_URL = '/acquisitions/underwriting-profiles',
  CUSTOM_FIELDS_URL = '/custom-fields',
  STATE_ASSUMPTIONS_URL = '/acquisitions/state-assumptions',
  PROPERTY_PROMOTIONS = '/property-promotions',
  CALLS_URL = '/calls',
  GROUP_EMAILS_URL = '/email-groups',
  SMS_MESSAGES_URL = '/sms-messages',
  COMMUNICATIONS_URL = '/communications',
  DASHBOARDS_URL = '/dashboards',
  FIXED_ASSETS_URL = '/fixed-assets',
  TICKET_TRIGGERS_URL = '/ticket-triggers',
  TICKET_TEMPLATES_URL = '/ticket-templates',
  FIXED_ASSET_CATEGORIES_URL = '/fixed-asset-categories',
  FIXED_ASSET_TYPES_URL = '/fixed-asset-types',
  ORGANIZATIONS_URL = '/organizations',
  PROPERTY_MANAGER_SERVICES_URL = '/property-manager-services',
  WARRANTIES_URL = '/warranties',
  WORK_ORDERS_URL = '/work-orders',
  SERVICES_URL = '/services',
  BIDS_URL = '/bids',
  JOB_URL = '/jobs',
  SAVED_FILTERS_URL = '/saved-filters',
  SERVICES_CATEGORIES = '/services/categories',
  SERVICES_SUBCATEGORIES = '/services/subcategories',
  SERVICES_REGIONS = '/service-regions',
  VENDOR_INSURANCE_POLICY_URL = '/insurance-policies',
  VENDOR_INSURANCE_COVERAGE_URL = '/vendor-insurance-coverages',
  USER_ROLE_TYPES_URL = '/user-role-types',
  VISITS_URL = '/visits',
  PERMISSIONS_URL = '/permissions',
  TOUCHES_URL = '/touches',
}

export enum EnumType {
  CUSTOM_FIELD_ENTITY_TYPE = 'custom-field-entity-type',
  FORM_QUESTION_TYPE = 'form-question-type',
  RESIDENT_COLLECTION_STATUS = 'resident-collection-status',
  EXPENSE_ACCOUNT_TYPE = 'expense-account-type',
  JOB_STATUS = 'job-status',
  EMAIL_TEMPLATE_RECIPIENT_TYPE = 'email-template-recipient-type',
  WORK_ORDER_PARTICIPANT_TYPE = 'work-order-participant-type',
  PARTY_TYPE = 'party-type',
  PORTFOLIO_TYPE = 'portfolio-type',
  BATTERY_MODE = 'battery-mode',
  LOCK_AUTO_RELOCK = 'lock-auto-relock',
  TICKET_TYPE = 'ticket-type',
  STATE = 'state',
  WARRANTY_TYPE = 'warranty-type',
  LEAD_STATUS = 'lead-status',
  LEASE_STATUS = 'lease-status',
  RESIDENT_COLLECTION_EVENT_TYPE = 'resident-collection-event-type',
  PARTY_ROLE_TYPE = 'party-role-type',
  TICKET_PRIORITY = 'ticket-priority',
  TICKET_RULE_TYPE = 'ticket-rule-type',
  CALL_STATUS = 'call-status',
  UNIT_STATUS = 'unit-status',
  PHONE_TYPE = 'phone-type',
  JOB_TYPE = 'job-type',
  PORTFOLIO_PROPERTY_TYPE = 'portfolio-property-type',
  LESSEE_TYPE = 'lessee-type',
  VISIT_STATUS = 'visit-status',
  CUSTOM_FIELD_TYPE = 'custom-field-type',
  SALUTATION = 'salutation',
  USER_STATUS = 'user-status',
  VENDOR_STATUS = 'vendor-status',
  ENTITY_TYPE = 'entity-type',
  THERMOSTAT_STATE = 'thermostat-state',
  COMMUNICATION_DIRECTION = 'communication-direction',
  NOTE_CONTEXT = 'note-context',
  WORK_ORDER_STATUS = 'work-order-status',
  PROPERTY_TYPE = 'property-type',
  CALL_QUEUE = 'call-queue',
  THERMOSTAT_FAN_STATE = 'thermostat-fan-state',
  NOTE_TYPE = 'note-type',
  WORK_ORDER_IMAGE_TYPE = 'work-order-image-type',
  INVOICE_PAYMENT_STATUS = 'invoice-payment-status',
  APPLICATION_TYPE = 'application-type',
  SHOWING_STATUS = 'showing-status',
  RESIDENT_COLLECTION_RISK = 'resident-collection-risk',
  THERMOSTAT_FAN_MODE = 'thermostat-fan-mode',
  PORTFOLIO_STATUS = 'portfolio-status',
  PAYMENT_METHOD_TYPE = 'payment-method-type',
  COMPANY_STATUS = 'company-status',
  SMART_HOME_SYSTEM_TYPE = 'smart-home-system-type',
  THERMOSTAT_MODE = 'thermostat-mode',
  FORM_ENTITY_TYPE = 'form-entity-type',
  WORK_ORDER_PRIORITY = 'work-order-priority',
  LOCK_VOLUME = 'lock-volume',
  LISTING_STATUS = 'listing-status',
  VENDOR_INSURANCE_COVERAGE_TYPE = 'vendor-insurance-coverage-type',
  RESIDENT_TYPE = 'resident-type',
  TRANSACTION_STATUS = 'transaction-status',
  RESIDENT_STATUS = 'resident-status',
  UNIT_APPLICATION_STATUS = 'unit-application-status',
  TICKET_STATUS = 'ticket-status',
  PERMISSION_CATEGORY = 'permission-category',
  PERMISSION_SUBCATEGORY = 'permission-subcategory',
  BID_STATUS = 'bid-status',
  WORK_ORDER_OCCUPANCY_STATUS = 'work-order-occupancy-status',
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const convertObjectToQueryString = (object: object, withQuestionMark = true) => {
  let qs = '';
  if (object !== null && typeof object !== 'undefined') {
    Object.entries(object).map(([key, value]) => {
      if (value !== null && typeof value !== 'undefined') {
        let sign;
        if (qs === '') {
          sign = withQuestionMark ? '?' : '';
        } else {
          sign = '&';
        }

        qs = `${qs}${sign}${key}=${encodeURIComponent(value)}`;
      }
      return null;
    });
  }
  return qs;
};
