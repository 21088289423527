import { handleError } from '@/utils/utils';
import deburr from 'lodash/deburr';
import groupBy from 'lodash/groupBy';
import type { FC, ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { Service, ServiceCategory, VendorService } from '../../../domain/services';
import { VendorServicesService } from '../../../services/vendorServices';
import { DARK_GREY, DARK_GREY_4, ICON_GREY } from '../../../theme/colors';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  ExpandMoreIcon,
  makeStyles,
  SearchIcon,
  TextField,
  Typography,
} from '../../../utils/material';
import message from '../../../utils/toast';

interface Props {
  onNext?: () => void;
  onSave?: () => void;
  vendorId?: number;
  hideNavigation?: boolean;
  loading: boolean;
  // These 3 must be required props, but can accept undefined if they're still loading
  categories: ServiceCategory[] | undefined;
  services: Service[] | undefined;
  vendorServices: VendorService[] | undefined;
}

const useStyles = makeStyles(() => ({
  loadingContainer: {
    marginTop: 30,
    textAlign: 'center',
  },
  stepDescription: {
    color: DARK_GREY,
    letterSpacing: 0.25,
    lineHeight: '1.25rem',
    fontSize: '0.75rem;',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    paddingBottom: 12,
  },
  accordionContainer: {
    marginTop: 24,
    overflow: 'auto',
  },
  searchIcon: {
    color: ICON_GREY,
  },
  servicesContainer: {
    width: '100%',
  },
  serviceContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    marginLeft: -16,
    marginRight: -16,
    paddingLeft: 16,
    paddingRight: 16,

    '&:hover': {
      backgroundColor: DARK_GREY_4,
    },
  },
  serviceName: {
    flex: 1,
  },
  categoryTitle: {
    fontSize: '0.75rem;',
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: 0.1,
    color: DARK_GREY,
  },
  button: {
    minWidth: 140,
  },
  chip: {
    height: 24,

    '& > span': {
      paddingLeft: 6,
      paddingRight: 6,
    },
  },
}));

const ServicesForm: FC<Props> = ({
  onNext,
  onSave,
  vendorId,
  categories,
  services,
  vendorServices,
  loading,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [originalSelectedIds, setOriginalSelectedIds] = useState<number[]>([]);
  const [selectedServicesIds, setSelectedServicesIds] = useState<number[]>([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setSelectedServicesIds((vendorServices || []).map((s) => s.serviceId));
    setOriginalSelectedIds((vendorServices || []).map((s) => s.serviceId));
  }, [vendorServices]);

  const isServiceSelected = (serviceId: number): boolean => selectedServicesIds.includes(serviceId);

  const toggleSelectedService = (service: Service) => () => {
    const alreadySelected = selectedServicesIds.includes(service.id);

    const newServicesList = !alreadySelected
      ? [...selectedServicesIds, service.id]
      : selectedServicesIds.filter((id) => id !== service.id);

    setSelectedServicesIds(newServicesList);
  };

  const servicesGroupedByCategory = useMemo(() => {
    const grouped = groupBy(services, 'categoryId');
    return Object.entries(grouped).map(([key, _services]) => ({
      categoryId: parseInt(key),
      services: _services,
    }));
  }, [services]);

  const getChipForCategory = (categoryId: number): ReactNode => {
    const currentService =
      servicesGroupedByCategory.find((sg) => sg.categoryId === categoryId)?.services ?? [];
    const total: number = currentService.length;
    const selectedTotal: number = currentService.filter((s) =>
      selectedServicesIds.includes(s.id),
    ).length;

    return (
      <Chip
        className={classes.chip}
        color={selectedTotal === 0 ? 'default' : 'primary'}
        label={`${selectedTotal}/${total}`}
      />
    );
  };

  const shouldShowService = (service: Service): boolean => {
    if (!searchTerm.length) {
      return true;
    }

    return deburr(service.name).toLowerCase().includes(deburr(searchTerm).toLowerCase());
  };

  const handleContinue = () => {
    const newIds = selectedServicesIds.filter(
      (selectedId) => !originalSelectedIds.includes(selectedId),
    );
    const idsToRemove = (vendorServices || [])
      .filter((vendorService) => !selectedServicesIds.includes(vendorService.serviceId))
      .map((service) => service.id);

    if (newIds.length === 0 && idsToRemove.length === 0) {
      message.info('There are no changes to be made');
      return;
    }

    setSaving(true);

    Promise.all([
      ...(newIds.length > 0 ? [VendorServicesService.bulk({ serviceIds: newIds, vendorId })] : []),
      ...idsToRemove.map((id) => VendorServicesService.delete(id)),
    ])
      .then(() => {
        onNext?.();
      })
      .catch((error) => {
        handleError(error, { toastMessage: 'There was an error saving selected services' });
      })
      .finally(() => {
        setSaving(false);
        onSave?.();
      });
  };
  const handleSave = () => {
    handleContinue();
  };

  return (
    <>
      <TextField
        style={{ marginTop: 16 }}
        size="small"
        fullWidth
        placeholder="Search"
        variant="outlined"
        onChange={(ev) => setSearchTerm(ev.target.value)}
        InputProps={{ endAdornment: <SearchIcon className={classes.searchIcon} /> }}
      />

      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      )}

      <div className={classes.accordionContainer}>
        {servicesGroupedByCategory.map(({ categoryId, services: _services }) => {
          const filteredServices = _services.filter(shouldShowService);
          const category = (categories || []).find((c) => c.id === categoryId);

          if (!filteredServices.length) {
            return null;
          }

          return (
            <Accordion key={categoryId}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.categoryTitle}>
                  {category?.name} {getChipForCategory(categoryId)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.servicesContainer}>
                  {filteredServices.map((service) => (
                    <div
                      key={service.id}
                      className={classes.serviceContainer}
                      onClick={toggleSelectedService(service)}
                    >
                      <div className={classes.serviceName}>{service.name}</div>
                      <Checkbox color="primary" checked={isServiceSelected(service.id)} />
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>

      <div className={classes.buttonsContainer}>
        {onNext && <Button onClick={onNext}>Skip for Now</Button>}
        {onNext && (
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            disabled={!selectedServicesIds.length || saving}
            onClick={handleContinue}
          >
            Continue
          </Button>
        )}
        {onSave && (
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            disabled={!selectedServicesIds.length || saving}
            onClick={handleSave}
          >
            Save
          </Button>
        )}
      </div>
    </>
  );
};

export default ServicesForm;
