import Page404 from '@/components/404';
import ErrorPage from '@/components/error';
import { Header } from '@/components/Header';
import { PageLoading } from '@/components/Loader';
import { RegistrationContext } from '@/contexts/registration';
import { Permissions } from '@/domain/permission';
import { PartyRoleType } from '@/domain/person';
import type { Vendor } from '@/domain/vendor';
import { useQuery } from '@/hooks/useQuery';
import useStorageEvent from '@/hooks/useStorageEvents';
import AuthCallbackPage from '@/pages/auth/callback';
import { RolesAndPermissions } from '@/pages/settings/rolesAndPermissions';
import ServicesProvided from '@/pages/settings/services-provided';
import { canContinueWithoutAuth, useFetch } from '@/services/apiClient';
import { DashboardService } from '@/services/dashboard';
import { SentryRoute, SentryService } from '@/services/sentry';
import { logout } from '@/utils/auth';
import { usePropifyContext } from '@/utils/globalContext';
import { Box, CircularProgress, makeStyles } from '@/utils/material';
import { hasOnePermissionOf, hasOrganizationPermissions } from '@/utils/utils';
import type { FC, ReactNode } from 'react';
import { Component, lazy, Suspense, useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect, Switch, useLocation } from 'react-router-dom';

const Layout = lazy(() => import('@/components/common/Layout'));
const AccountPage = lazy(() => import('@/pages/account'));
const BidPage = lazy(() => import('@/pages/bids'));
const CalendarPage = lazy(() => import('@/pages/calendar'));
const DashboardPage = lazy(() => import('@/pages/dashboard'));
const InsurancePage = lazy(() => import('@/pages/insurance'));
const CreateJobPage = lazy(() => import('@/pages/jobs/create'));
const JobsSingle = lazy(() => import('@/pages/jobs/single'));
const RegistrationPage = lazy(() => import('@/pages/registration'));
const SettingsPage = lazy(() => import('@/pages/settings'));
const ServiceRegionsPage = lazy(() => import('@/pages/settings/service-regions'));
const UsersPage = lazy(() => import('@/pages/settings/users'));
const ManageUser = lazy(() => import('@/pages/settings/users/manage'));
const BidsPage = lazy(() => import('@/pages/work-orders/bids'));
const CompletedWorkOrdersPage = lazy(() => import('@/pages/work-orders/completed'));
const OpenWorkOrdersPage = lazy(() => import('@/pages/work-orders/open'));
const ProfileWorkOrdersPage = lazy(() => import('@/pages/work-orders/profile'));

export interface LayoutChildrenProps {
  routes?: CustomRoute[];
  vendor?: Vendor;
  loadVendor?: () => void;
}

type CustomRoute = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: FC<RouteComponentProps<any> & LayoutChildrenProps>;
  path: string;
  routes?: CustomRoute[];
  organizationPermission: PartyRoleType[];
  rolePermissions?: Permissions[];
};

const useStyles = makeStyles(() => ({
  registrationStepContainer: {
    height: '100%',
    maxHeight: 'calc(100vh - 56px)',
    overflow: 'auto',
  },
}));

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryStage {
  error: unknown;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryStage> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error: unknown) {
    return { error: error };
  }

  componentDidCatch(error: Error) {
    SentryService.trackError(error);
  }

  render() {
    if (this.state.error) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

const routes: CustomRoute[] = [
  {
    path: '/dashboard',
    component: DashboardPage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
  },
  {
    path: '/account',
    component: AccountPage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
  },
  {
    path: '/calendar',
    component: CalendarPage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
  },
  {
    path: '/settings/services-provided',
    component: ServicesProvided,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
    rolePermissions: [Permissions.READ_SERVICE],
  },
  {
    path: '/settings',
    component: SettingsPage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
  },
  {
    path: '/users',
    component: UsersPage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
    rolePermissions: [Permissions.READ_USER],
  },
  {
    path: '/service-regions',
    component: ServiceRegionsPage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
    rolePermissions: [Permissions.READ_SERVICE_REGION],
  },
  {
    path: '/user/manage/:id',
    component: ManageUser,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
    rolePermissions: [Permissions.UPDATE_USER],
  },
  {
    path: '/jobs/create',
    component: CreateJobPage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
    rolePermissions: [Permissions.CREATE_JOB],
  },
  {
    path: '/jobs/single',
    component: JobsSingle,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
    rolePermissions: [Permissions.READ_JOB],
  },
  {
    path: '/work-orders/completed',
    component: CompletedWorkOrdersPage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
    rolePermissions: [Permissions.READ_WORK_ORDER],
  },
  {
    path: '/work-orders/open',
    component: OpenWorkOrdersPage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
    rolePermissions: [Permissions.READ_WORK_ORDER],
  },
  {
    path: '/work-orders/bids/:filter',
    component: BidsPage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
  },
  {
    path: '/work-orders/bids',
    component: BidsPage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
  },
  {
    path: '/work-orders/:id',
    component: ProfileWorkOrdersPage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
    rolePermissions: [Permissions.READ_WORK_ORDER],
  },
  {
    path: '/roles-and-permissions',
    component: RolesAndPermissions,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
  },
  {
    path: '/insurance',
    component: InsurancePage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
    rolePermissions: [Permissions.READ_INSURANCE],
  },
  {
    path: '/bids/:id',
    component: BidPage,
    organizationPermission: [PartyRoleType.VENDOR, PartyRoleType.PROPERTY_MANAGER],
    rolePermissions: [Permissions.READ_WORK_ORDER],
  },
];

const Routes = () => {
  const { user } = usePropifyContext();
  const query = useQuery();
  const location = useLocation();

  // Dummy state to re-render when the permissions are loaded
  const [, setStorageValues] = useState('');
  const updateStorageValues = () =>
    setStorageValues(
      `${localStorage.getItem('permissions')}${localStorage.getItem('access_token')}`,
    );

  useStorageEvent('permissions', updateStorageValues);
  useStorageEvent('access_token', updateStorageValues);

  const { data: badgeCounts, mutate: refetchBadgeCounts } = useFetch({
    fetcher: user ? DashboardService.getMaintenanceSummarized : undefined,
  });

  useEffect(() => {
    refetchBadgeCounts();
  }, [refetchBadgeCounts, location.pathname]);

  const getRouteWithLayout = (route: CustomRoute) => {
    const canRenderComponent =
      hasOrganizationPermissions(route.organizationPermission) &&
      hasOnePermissionOf(route.rolePermissions || []);

    return (
      <SentryRoute
        key={route.path}
        exact
        path={route.path}
        render={(props) => (
          <Suspense fallback={<PageLoading />}>
            <Layout badgeCounts={badgeCounts}>
              {canRenderComponent ? (
                <Suspense
                  fallback={
                    <Box alignItems="center">
                      <CircularProgress />
                    </Box>
                  }
                >
                  <ErrorBoundary>
                    <route.component {...props} routes={route.routes} />
                  </ErrorBoundary>
                </Suspense>
              ) : (
                <Page404 />
              )}
            </Layout>
          </Suspense>
        )}
      />
    );
  };

  const [onSaveAndExit, setOnSaveAndExit] = useState<() => void>(() => logout);
  const [showSaveAndExit, setShowSaveAndExit] = useState(false);
  const classes = useStyles();

  return (
    <>
      {!user && !canContinueWithoutAuth() && <PageLoading />}
      <Switch>
        <SentryRoute path="/auth/callback">
          <ErrorBoundary>
            <AuthCallbackPage
              refreshToken={query.get('refreshToken')}
              returnTo={query.get('returnTo')}
            />
          </ErrorBoundary>
        </SentryRoute>

        <SentryRoute exact path="/">
          <Redirect to="/dashboard" />
        </SentryRoute>

        <SentryRoute path="/registration">
          <ErrorBoundary>
            <RegistrationContext.Provider
              value={{ onSaveAndExit, setOnSaveAndExit, showSaveAndExit, setShowSaveAndExit }}
            >
              <Header />
              <Box className={classes.registrationStepContainer}>
                <Suspense fallback={<PageLoading />}>
                  <RegistrationPage />
                </Suspense>
              </Box>
            </RegistrationContext.Provider>
          </ErrorBoundary>
        </SentryRoute>

        {routes.map((route) => getRouteWithLayout(route))}

        <SentryRoute path="*">
          <Page404 />
        </SentryRoute>
      </Switch>
    </>
  );
};

export default Routes;
