import { useState } from 'react';
import { ChecklistIcon } from '../../../assets/images/icons';
import { Permissions } from '../../../domain/permission';
import type { Service, ServiceCategory } from '../../../domain/services';
import { useFetch } from '../../../services/apiClient';
import { ServicesService } from '../../../services/services';
import { VendorServicesService } from '../../../services/vendorServices';
import {
  Box,
  Button,
  Checkbox,
  CloseIcon,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  InfoIcon,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '../../../utils/material';
import { hasPermissions } from '../../../utils/utils';
import ServicesForm from '../../registration/components/ServicesForm';
import StepTitle from '../../registration/components/StepTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'auto',
  },
  drawer: {
    borderRight: '1px solid rgb(0 0 0 / 10%)',
    width: 300,
    display: 'flex',
    flexDirection: 'column',
  },
  categoriesList: {
    overflow: 'auto',
    flex: 1,
  },
  drawerHeader: {
    borderBottom: '1px solid #eaeaea',
    padding: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 42,

    '& h3': {
      marginLeft: 10,
    },
  },
  grid: {
    width: '100%',
    padding: 10,
  },
  gridPaper: {
    display: 'grid',
    padding: 10,
  },
  extension: {
    background: 'rgb(0 0 0 / 4%)',
    borderRadius: 4,
    textAlign: 'center',
    padding: 10,
  },
  fileTitle: {
    margin: '5px 0',
  },
  gridHeader: {
    padding: 4,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 42,
  },
  containerFiles: {
    display: 'block',
    margin: '0 10px',
  },
  popoverConfirm: {
    padding: 10,
    display: 'grid',
    justifyContent: 'center',
  },
  selectedCategory: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& > ul': {
      flex: 1,
      overflow: 'auto',
    },
  },
  selectedCategoryInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',

    '& .MuiPaper-root': {
      width: '100%',
      margin: 10,
      padding: 7,
    },
    '& h3': {
      alignItems: 'center',
      display: 'flex',
      margin: 0,
      padding: 9,
      height: 42,

      '& .MuiSvgIcon-root': {
        color: '#0f6eff',
        marginRight: 5,
      },
    },
  },
  itemActive: {
    background: '#0f6eff',
    color: '#fff',

    '& .MuiListItemText-secondary': {
      color: '#d0d0d0',
    },
    '&:hover': {
      background: '#0c5ad2',
    },
  },
  fileRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 5,
  },
  alignCenter: {
    alignItems: 'center',
    display: 'flex',
  },
  dialogEditServies: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  box: {
    padding: '16px 0px 0px 0px',
    width: '100%',
  },
  infoText: {
    marginLeft: '16px',
    marginBottom: '5px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ServicesProvided = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<ServiceCategory>();
  const canEditServices = hasPermissions(Permissions.UPDATE_SERVICE);

  const {
    data: vendorServices,
    isValidating: loadingVendors,
    mutate: updateVendors,
  } = useFetch({
    fetcher: VendorServicesService.search,
    errorMessage: 'There was an error fetching the vendor services',
  });

  const { data: categories, isValidating: loadingCategories } = useFetch({
    fetcher: ServicesService.getCategories,
    errorMessage: 'There was an error fetching the categories',
  });

  const { data: services, isValidating: loadingServices } = useFetch({
    fetcher: ServicesService.search,
    errorMessage: 'There was an error fetching the services',
  });

  const loading =
    (!(services || []).length && loadingServices) ||
    (!(vendorServices || []).length && loadingVendors) ||
    (!(categories || []).length && loadingCategories);

  const switchVendor = (category: ServiceCategory) => {
    setSelectedCategory(category);
  };

  const getServicesFiltered = (categoryId: number) =>
    (services || []).filter((service) => service.categoryId === categoryId);

  const isServiceSelected = (service: Service) =>
    (vendorServices || []).some((vendor) => vendor.serviceId === service.id);

  const renderBadgeServicesSelected = (category: ServiceCategory) => {
    const findVendorService = (vendorServices || []).filter(
      (vendor) => vendor.categoryId === category.id,
    );
    return `(${findVendorService.length || 0}/${getServicesFiltered(category.id)?.length || 0})`;
  };

  const renderCategoriesList = () => {
    return (
      <List component="ul" aria-label="categories" className={classes.categoriesList}>
        {categories?.map((category) => (
          <ListItem
            key={category.id}
            onClick={() => switchVendor(category)}
            button
            className={`${category.id === selectedCategory?.id ? classes.itemActive : ''}`}
          >
            <ListItemText primary={`${category.name} ${renderBadgeServicesSelected(category)}`} />
          </ListItem>
        ))}
      </List>
    );
  };

  const onSaveServices = () => {
    setShowEditDialog(false);
    updateVendors();
  };

  const renderDialogServices = () => {
    return (
      <Dialog open={showEditDialog}>
        <DialogTitle>
          <StepTitle
            icon={ChecklistIcon}
            title="Services"
            description="Please select all the services that your company provides. This will let property managers know which work orders can be assigned to you."
          />
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => setShowEditDialog(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div className={classes.dialogEditServies}>
          <ServicesForm
            hideNavigation={false}
            onSave={() => onSaveServices()}
            categories={categories}
            services={services}
            vendorServices={vendorServices}
            loading={loading}
          />
        </div>
      </Dialog>
    );
  };

  const renderDrawerHeader = () => {
    return (
      <div className={classes.drawerHeader}>
        <h3>Services Provided</h3>
      </div>
    );
  };

  const renderDrawer = () => {
    return (
      <div className={classes.drawer}>
        {renderDrawerHeader()}
        {renderCategoriesList()}
        {renderDialogServices()}
      </div>
    );
  };

  const renderSelectedCategory = () => {
    if (!selectedCategory) {
      return null;
    }

    const selectedCategoryServices = getServicesFiltered(selectedCategory.id) || [];

    return (
      <div className={classes.selectedCategory}>
        <div className={classes.selectedCategoryInfo}>
          <h3>
            <InfoIcon /> {selectedCategory.name} {renderBadgeServicesSelected(selectedCategory)}
          </h3>
          {canEditServices && (
            <Button
              variant="contained"
              disableElevation
              color="primary"
              size="small"
              onClick={() => setShowEditDialog(true)}
              style={{ marginRight: '15px' }}
            >
              Edit
            </Button>
          )}
        </div>
        <Divider />
        <List dense>
          {selectedCategoryServices.map((service) => (
            <ListItem key={service.id} button>
              <ListItemText primary={service.name} />
              <ListItemSecondaryAction>
                <Checkbox edge="end" checked={isServiceSelected(service)} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    );
  };

  if (loading) {
    return (
      <Box textAlign="center" p={2}>
        Loading...
      </Box>
    );
  }

  return (
    <div className={classes.root} style={{ display: !isMobile ? 'flex' : 'block' }}>
      {!isMobile && renderDrawer()}

      {isMobile && (
        <>
          {renderDrawerHeader()}
          {renderCategoriesList()}
        </>
      )}

      {selectedCategory ? (
        renderSelectedCategory()
      ) : (
        <Box className={classes.box}>
          <div className={classes.infoText}>Select a provided service</div>
          <Divider />
        </Box>
      )}
    </div>
  );
};

export default ServicesProvided;
